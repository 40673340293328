const initialState = {
  uid: false,
  brandId: false,
  streamId: false,
  appIsReady: false,
  stream: {
    isStreaming: false,
  },
};

const ADD_USER_ID = "ADD_USER_ID"
const ADD_INITIAL_DATA = "ADD_INITIAL_DATA";
const TOGGLE_APP_IS_READY = "TOGGLE_APP_IS_READY"
const REMOVE_ALL_DATA = "REMOVE_ALL_DATA";
const ADD_STREAM_DETAILS = "ADD_STREAM_DETAILS";


export const addUserId = (uid) => ({
  type: ADD_USER_ID,
  uid,
});

export const addInitialData = (data) => ({
  type: ADD_INITIAL_DATA,
  data,
});

export const toggleAppIsReady = (appIsReady) => ({
  type: TOGGLE_APP_IS_READY,
  appIsReady,
});

export const removeAllData = (data = {}) => ({
  type: REMOVE_ALL_DATA,
  data,
});

export const addStreamDetails = (stream) => ({
  type: ADD_STREAM_DETAILS,
  stream,
});

const State = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_ID:
      return { ...state, ...action.uid };
    case ADD_INITIAL_DATA:
      return { ...state, ...action.data };
    case TOGGLE_APP_IS_READY:
      return { ...state, ...action.appIsReady };
    case REMOVE_ALL_DATA:
      return { ...initialState }
    case ADD_STREAM_DETAILS:
      return { ...state, stream: action.stream };


    // case UPDATE_USER_DETAILS: {
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       email: action.user.email ? action.user.email : state.user.email,
    //       id: action.user.id ? action.user.id : state.user.id,
    //       representsBrandId: action.user.representsBrandId
    //         ? action.user.representsBrandId
    //         : state.user.representsBrandId,
    //       representsBrandName: action.user.representsBrandName
    //         ? action.user.representsBrandName
    //         : state.user.representsBrandName,
    //       name: action.user.name ? action.user.name : state.user.name,
    //       firstName: action.user.firstName
    //         ? action.user.firstName
    //         : state.user.firstName,
    //       lastName: action.user.lastName
    //         ? action.user.lastName
    //         : state.user.lastName,
    //       role: action.user.role ? action.user.role : state.user.role,
    //       userApplicationVideoLink: action.user.userApplicationVideoLink
    //         ? action.user.userApplicationVideoLink
    //         : state.user.userApplicationVideoLink,
    //       userApplicationStatus: action.user.userApplicationStatus
    //         ? action.user.userApplicationStatus
    //         : state.user.userApplicationStatus,
    //       userBrandAssociationDocumentId: action.user
    //         .userBrandAssociationDocumentId
    //         ? action.user.userBrandAssociationDocumentId
    //         : state.user.userBrandAssociationDocumentId,
    //       userApplicationVideoLinkCreated: action.user
    //         .userApplicationVideoLinkCreated
    //         ? action.user.userApplicationVideoLinkCreated
    //         : state.user.userApplicationVideoLinkCreated,
    //       stripeCustomerId: action.user.stripeCustomerId
    //         ? action.user.stripeCustomerId
    //         : state.user.stripeCustomerId,
    //       subscriptionStatus: action.user.subscriptionStatus
    //         ? action.user.subscriptionStatus
    //         : state.user.subscriptionStatus,
    //       subscriptionId: action.user.subscriptionId
    //         ? action.user.subscriptionId
    //         : state.user.subscriptionId,
    //       representsBrandWebsiteURL: action.user.representsBrandWebsiteURL
    //         ? action.user.representsBrandWebsiteURL
    //         : state.user.representsBrandWebsiteURL,
    //       representsBrandSupportsiteURL: action.user
    //         .representsBrandSupportsiteURL
    //         ? action.user.representsBrandSupportsiteURL
    //         : state.user.representsBrandSupportsiteURL,
    //       billingAddress: action.user.billingAddress
    //         ? action.user.billingAddress
    //         : state.user.billingAddress,
    //       subscriptionPlanId: action.user.subscriptionPlanId
    //         ? action.user.subscriptionPlanId
    //         : state.user.subscriptionPlanId,
    //     },
    //   };
    // }
    // case ADD_STREAM_DETAILS:
    //   return { ...state, stream: action.stream };
    default:
      return state;
  }
};

export default State;
